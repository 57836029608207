/* src/components/Footer.css */

.footer {
    background-color: #333; /* Dark background */
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .footer a {
    color: #D35400; /* Pumpkin orange for links */
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer-content p {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
  
  .social-icons {
    margin-top: 1rem;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #E67E22; /* Highlight color on hover */
  }