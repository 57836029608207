/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem; /* Reduced padding to prevent shifting */
    position: absolute; /* Changed to fixed */
    top: 0; /* Ensure it starts at the very top */
    left: 0;
    right: 0;
    z-index: 3; /* Keep it above other content */
    background-color: rgba(0, 0, 0, 0.0); /* Optional: Add a semi-transparent background if desired */
  }
  
  .menu-button {
    position: relative; /* Changed to relative to avoid overlap */
    z-index: 3;
    cursor: pointer;
  }
  
  .hamburger {
    width: 40px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease;
  }
  
  .hamburger span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.3s ease;
  }
  
  .hamburger.open span:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
  }
  
  .hamburger.open span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
  }
  
  /* Menu dropdown styling */
  .menu-dropdown {
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 40px;
    right: 0;
    width: 150px; /* Increased width for more space */
    text-align: center; /* Center the text in the dropdown */
  }
  .menu-dropdown a {
    color: #333;
    text-decoration: none;
    padding: 5px;
    display: block;
    font-weight: bold;
  }
  
  /* Hover effect for the link */
  .menu-dropdown a:hover {
    color: #bf801b; /* Accent color on hover */
  }

  /* Logo box styling - positioned absolutely within the hero */
  .logo-box {
    position: relative;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0); /* Semi-transparent white background */
    padding: 10px;
    border-radius: 8px;
    z-index: 3;
  }

  .logo {
    height: auto; /* Adjust as needed */
    max-width: 300px;
    width: 100%;
  }

  @media (max-width: 600px) {
    .logo-box {
      max-width: 200px; /* Smaller size for mobile screens */
      max-height: auto;
    }
  }