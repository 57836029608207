  /* Default (Light Mode) Bulma Variables */
    :root {
      --background-color: #ffffff;
      --primary-color: #E67E22; /* Construction-themed orange */
      --text-color: #333333;
   }
  
  /* Dark Mode Variables */
  @media (prefers-color-scheme: dark) {
    :root {
      --background-color: #ffffff;
      --primary-color: #E67E22; /* Brighter color for dark mode */
      --text-color: #333333;
    }
  }
  
  /* Override Bulma Colors Using Variables */
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  /* .navbar, .footer {
    background-color: var(--background-color);
    color: var(--text-color);
  } */
  
  .button.is-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #ffffff;
  }
  