/* src/components/Gallery.css */
/* src/components/Gallery.css */
  .gallery {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
    padding: 3rem 2rem;
    max-width: 1600px; /* Limit width for better readability */
    margin: 3rem auto 3rem 0; /* Align section to the left */
    text-align: left;
    font-family: 'Montserrat', sans-serif;
  }

  .gallery.fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  .gallery h2 {
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #333;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
    transform: translateX(-50%); /* Start from center */
    opacity: 0;
    animation: titleFadeIn 2s forwards ease-out; /* Animate from center to left */
  }

  .gallery h2::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #E67E22;
    margin: 1rem 0;
    border-radius: 2px;
  }

  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .gallery-item {
    position: relative;
    cursor: pointer;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
 .gallery-item .gallery-title {
   position: absolute;
   bottom: 10px;
   left: 10px;
   color: white;
   background: rgba(0, 0, 0, 0.5);
   padding: 0.5rem;
   border-radius: 4px;
 }
