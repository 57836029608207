/* src/components/ContactPage.css */

.contact-page {
    background-color: #000;
    font-family: 'Montserrat', sans-serif;
  }
  
  /* Text Styling */
  .contact-page .title{
    color: rgba(255, 255, 255, 1);; /* Brighter white */
  }
  /* Text Styling */
  .contact-page .subtitle{
    color: rgb(255, 255, 255);; /* Brighter white */
  }

  .image-section {
    height: 300px;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
  }
  
  .contact-section {
    padding: 1rem;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat', sans-serif;
  }
  
  .contact-form .button {
    background-color: #E67E22;
    color: white;
  }

  .notification {
    text-align: center;
    margin-top: 1rem;
  }
  