/* src/components/MissionStatement.css */

.mission-statement {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    padding: 3rem 2rem;
    max-width: 800px; /* Limit width for better readability */
    margin: 3rem auto 3rem 0; /* Align section to the left */
    text-align: match-parent;
    font-family: 'Montserrat', sans-serif;
  }
  
  .mission-statement.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .mission-statement h2 {
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #333;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
    transform: translateX(-50%); /* Start from center */
    opacity: 0;
    animation: titleFadeIn 2s forwards ease-out; /* Animate from center to left */
  }
  
  .mission-statement h2::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #E67E22;
    margin: 1rem 0;
    border-radius: 2px;
  }
  
  .mission-statement p {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.8;
    color: #555;
    letter-spacing: 0.5px;
    opacity: 0;
    transform: translateY(20px); /* Start below */
    animation: paragraphFadeIn 2s forwards ease-out 1s; /* Delayed fade-in */
  }
  
  .mission-statement p .highlight {
    color: #E67E22;
    font-weight: 600;
  }
  
  /* Title Animation */
  @keyframes titleFadeIn {
    0% {
      transform: translateX(-50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Paragraph Animation */
  @keyframes paragraphFadeIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  