/* src/components/Modal.css */

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1000;
    overflow: hidden; /* Prevent any scrolling */
  }
  
  .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Dark overlay background */
  }
  
  .modal-content {
    position: relative;
    z-index: 1001;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: auto;
    border-radius: 8px;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
  }
  
  .clickable-area {
    position: absolute;
    top: 0;
    width: 50%; /* Each half of the image */
    height: 100%;
    cursor: pointer;
    z-index: 2; /* Above image but below arrows */
  }
  
  .clickable-area.left {
    left: 0;
  }
  
  .clickable-area.right {
    right: 0;
  }
  
  /* Arrows */
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3; /* Ensure arrows are above clickable areas */
  }
  
  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
  
  .arrow:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .modal-close {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1002;
  }
  