/* src/components/MissionStatement.css */

.our-process {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    padding: 3rem 2rem;
    max-width: 800px; /* Limit width for better readability */
    margin: 3rem auto 3rem 0; /* Align section to the left */
    text-align: match-parent;
    font-family: 'Montserrat', sans-serif;
  }
  
  .our-process.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .our-process h2 {
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #333;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
    transform: translateX(-50%); /* Start from center */
    opacity: 0;
    animation: titleFadeIn 2s forwards ease-out; /* Animate from center to left */
  }

  .our-process h2 .highlight {
    color: #E67E22;
    font-weight: 600;
  }

  .our-process h2::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #E67E22;
    margin: 1rem 0;
    border-radius: 2px;
  }
  
  .our-process p {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.8;
    color: #555;
    letter-spacing: 0.5px;
    opacity: 0;
    transform: translateY(20px); /* Start below */
    animation: paragraphFadeIn 2s forwards ease-out 1s; /* Delayed fade-in */
  }
  
  .our-process p .highlight {
    color: #E67E22;
    font-weight: 600;
  }
  
  /* Title Animation */
  @keyframes titleFadeIn {
    0% {
      transform: translateX(-50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Paragraph Animation */
  @keyframes paragraphFadeIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
.building-animation-container {
  height: 500vh;
  background: #f7f7f7;
}

.sticky-container {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation-content {
  max-width: 1400px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.phase-descriptions {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  height: 400px;
  position: relative;
  margin-top: -100px;
}

.phase-content {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  width: 100%;
}

.phase-item {
  position: absolute;
  width: 100%;
  padding: 20px;
}

.phase-descriptions .phase-item h3 {
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  margin: 0 0 1rem 0;
  color: #333;
  position: relative;
  transition: color 0.3s ease;
  font-weight: 700;  /* Make headers bold */
}

.phase-descriptions .phase-item h3::after {
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  background-color: #E67E22;
  margin: 0.5rem 0;
  border-radius: 2px;
}

.phase-descriptions .phase-item p {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  line-height: 1.6;
  color: #000;  /* Change paragraph text to black */
  margin: 0;
  padding-left: 10px;
  border-left: 3px solid #E67E22;
  transition: all 0.3s ease;
}

.spacer {
  height: 300vh;
}

@media (max-width: 768px) {
  .building-animation-container {
    height: 450vh;
  }

  .animation-content {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    min-height: 100vh;
  }

  .phase-descriptions {
    width: 100%;
    max-width: 100%;
    margin-top: -200px;
    height: 300px;
    padding-bottom: 60px;
  }

  svg {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-top: -100px;
  }

  .spacer {
    height: 150vh;
  }
}

@media (max-width: 480px) {
  .phase-descriptions {
    margin-top: -180px;
    height: 250px;
    padding-bottom: 80px;
  }
}
  