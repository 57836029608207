/* src/components/HeroSection.css */

.hero-background {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1.5s ease-in-out; /* Smooth transition for background */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay */
  z-index: 1;
}

.center-text {
  color: rgba(255, 255, 255, 1);
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  z-index: 2;
  /* text-align: left; */
  font-family: 'Montserrat', sans-serif;
  position: relative;
}

/* Responsive design adjustments */
@media (min-width: 768px) {
  .hero-background {
    height: 600px;
  }
}
